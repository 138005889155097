import { TableStationConnectivityListMapper } from "src/app/mapper/table/table-station-connectivity-list.mapper";
import { StationFilterType } from "src/app/shared/models/station/station-filter-type";

/**
 * The column ids for the {@link TableStationConnectivityListMapper}
 */
export type TableStationConnectivityListColumn =
  | typeof StationFilterType.NAME
  | typeof StationFilterType.CODE
  | typeof StationFilterType.NETWORK
  | "DISCONNECT_DATE"
  | "DISCONNECT_DURATION";

export namespace TableStationConnectivityListColumn {
  export const NAME = "NAME";
  export const CODE = "CODE";
  export const NETWORK = "NETWORK";
  export const DISCONNECT_DATE = "DISCONNECT_DATE";
  export const DISCONNECT_DURATION = "DISCONNECT_DURATION";

  export function values(): readonly TableStationConnectivityListColumn[] {
    return [
      NAME,
      CODE,
      NETWORK,
      DISCONNECT_DATE,
      DISCONNECT_DURATION,
    ];
  }
}
