/* tslint:disable */

/**
 * The origin of the auth support:
 *   * API
 *   * TRANSACTION
 *   * IMPORT
 *   * MANUAL
 */
type AuthSupportOrigin =
  'API' |
  'TRANSACTION' |
  'IMPORT' |
  'MANUAL';
module AuthSupportOrigin {
  export const API: AuthSupportOrigin = 'API';
  export const TRANSACTION: AuthSupportOrigin = 'TRANSACTION';
  export const IMPORT: AuthSupportOrigin = 'IMPORT';
  export const MANUAL: AuthSupportOrigin = 'MANUAL';
  export function values(): AuthSupportOrigin[] {
    return [
      API,
      TRANSACTION,
      IMPORT,
      MANUAL
    ];
  }
}

export { AuthSupportOrigin }