import {NgxLoggerLevel} from 'ngx-logger';
import {TimezoneType} from 'src/app/enums/timezone-type';
import {AppGuardType} from 'src/app/tinea-components/okta/enum/AppGuardType';
import {DataConsumeType} from 'src/app/tinea-components/okta/enum/DataConsumeType';
import packageInfo from '../../package.json';
import {coreEnvironment} from './core/core.environment';
import {dashboardEnvironment} from './core/dashboard.environment';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    core: coreEnvironment,
    production: true,
    showAlarmv2primeng: false,
    VERSION: packageInfo.version,
    timezone: TimezoneType.LOCAL,
    versionNameInSession: 'version',
    apiUrl: '/prod',
    apiKeyPublic: '8349a3e2-5bf8-4fad-ac22-b0e3d5121b7c',
    publicApiUrl: '/prod-public',
    urlPublicWwebsite: 'https://stations.gnvert.com/map',
    // google Map
    googleMapKey: 'AIzaSyALn8LlQgSiiZWZTr_QqwdVdKgYWaFpFxM',
    defaultLatitude: 45.95114969,
    defaultLongitude: 2.41699219,
    defaultZoom: 6,
    defaultFullscreenControl: false,
    defaultMapTypeControl: false,
    // logs
    disableConsoleLogging: false,
    browserLogLevel: NgxLoggerLevel.ERROR,
    serverLogLevel: NgxLoggerLevel.ERROR,
    // encryption
    encryptUserSession: false,
    encryptionSecretKey: '!j5;/q3XsBxA5@,78CLPAw_[TP[.@L67ddf?6bh;?#]&U',
    // auth
    authClass: 'AuthentWithCustomOktaService',
    authAppGuard: AppGuardType.AUTHENTICATED_BY_CUSTOM_OKTA_THEN_LOGGEDIN,
    authLoginInternal: false, // eg. login by internal form (true) ? or by an other website (false) ?
    authRedirectToPreviousUrl: true,
    authPathRedirectAfterLogin: '/',
    authPathRedirectAfterLogout: '/',
    authPathLoggedInCallback: 'logged-in-callback',
    // key names
    authRedirectToNameInSession: 'redirectTo',
    authTokenNameInSession: 'authToken',
    authUserNameInSession: 'user',
    authExpireAtNameInSession: 'authExpireAt',
    // consumes api
    dataStartApiCall: ['/prod'],
    dataConsumeType: DataConsumeType.AUTH,
    dataHeaders: [
        {
            nameInEnv: null,
            headerName: 'Authorization',
            mustDecode: false,
            fieldToDecode: 'uid',
        },
    ], // data needed in header for consume data [ {nameInEnv: null, headerName: 'Authorization',mustDecode: false, fieldToDecode: null} ]
    userId: 'root',
    search: 'search',
    // key names
    dataTokenTypeNameInSession: 'tokenType',
    dataTokenTypeValueInSession: 'Bearer',
    dataTokenNameInSession: 'dataToken',
    // okta
    okta_client_id: '0oa4wkinkoyMuyNEM0i7',
    okta_domain: 'https://tinea.okta-emea.com',
    okta_issuer: `https://tinea.okta-emea.com/oauth2/aus4wmrjnciXELzbv0i7`,
    okta_redirectUri: window.location.origin + '/implicit/callback',
    // okta_redirectUri: window.location.origin + '/implicit/callback',
    okta_scope: 'shiva openid',
    brand_name: 'SHIVA',
    // okta custom
    oktaCustom: {
        okta_client_id: '0oa4wkinkoyMuyNEM0i7',
        okta_domain: 'https://tinea.okta-emea.com',
        okta_issuer: 'https://tinea.okta-emea.com/oauth2/aus4wmrjnciXELzbv0i7',
        response_type: 'code',
        redirect_uri: window.location.origin + '/implicit/custom-callback',
        post_logout_redirect_uri: window.location.origin + '/',
        scopes: 'shiva openid',
        state: 'YYYY',
        nonce: 'YYYY',
        code_challenge: 'yHR817RK2NrYK7p8SjxUkrQdotCpIIZLQS1ShqzcMoo',
        code_verifier:
            'ku54BtgXiqvFGojj6pyRCiGmXVkj0GdI6YIPfWCMV0yAdM8bSn0H2ftOObiEaCEj2shmZmSHWK1tAOOC7q5Zd0c0IYM3Zahtz20ILC0BQIXKNuWPrIrZkr7Hkop029t5',
        code_challenge_method: 'S256',
        grant_type: 'authorization_code',
    },
    // api manager
    getTokenBodyParams: JSON.stringify([
        'grant_type=urn:ietf:params:oauth:grant-type:jwt-bearer',
        'assertion',
    ]),
    application: '',
    // keyId: 'a01f30b7-9080-4b37-913d-f0bc9587e14f',
    client_id: '',
    // client_secret: '707c516d-6353-42b3-b42f-863b14c1109f',
    tineaTokenUrl: '',
    // configs
    isEditableCreatable: {
        vehicle: false,
    },
    // cube js
    urlDashboard: 'https://gnvert-dashboard.digital.equans.com',
    cubeUrlApi: '/cubejs',

    // ENERGIES
    energyTypeIconsPath: {
        ELECTRICITY: '/assets/images/energies/electricity.svg',
        HYDROGEN: '/assets/images/energies/hydrogen.svg',
        GNL: '/assets/images/energies/gas.svg',
        GNC: '/assets/images/energies/gas.svg',
        GNCL: '/assets/images/energies/gas.svg',
        BIOGNC: '/assets/images/energies/gas.svg',
    },
    globalVehiculeEnergyPath: {
        ELECTRICITY: '/assets/images/energies/electricity.svg',
        ELECTRICITY_AVAILABLE: '/assets/images/energies/electricity-available.svg',
        ELECTRICITY_PARTIALLY: '/assets/images/energies/electricity-partially.svg',
        ELECTRICITY_UNAVAILABLE:
            '/assets/images/energies/electricity-unavailable.svg',
        ELECTRICITY_UNKNOWN: '/assets/images/energies/electricity-unknown.svg',
        HYDROGEN: '/assets/images/energies/hydrogen.svg',
        HYDROGEN_AVAILABLE: '/assets/images/energies/hydrogen-available.svg',
        HYDROGEN_PARTIALLY: '/assets/images/energies/hydrogen-partially.svg',
        HYDROGEN_UNAVAILABLE: '/assets/images/energies/hydrogen-unavailable.svg',
        HYDROGEN_UNKNOWN: '/assets/images/energies/hydrogen-unknown.svg',
        GAS: '/assets/images/energies/gas.svg',
        GAS_AVAILABLE: '/assets/images/energies/gas-available.svg',
        GAS_PARTIALLY: '/assets/images/energies/gas-partially.svg',
        GAS_UNAVAILABLE: '/assets/images/energies/gas-unavailable.svg',
        GAS_UNKNOWN: '/assets/images/energies/gas-unknown.svg',
        HYBRID: '/assets/images/energies/hybrid.svg',
        HYBRID_AVAILABLE: '/assets/images/energies/hybrid-available.svg',
        HYBRID_PARTIALLY: '/assets/images/energies/hybrid-partially.svg',
        HYBRID_UNAVAILABLE: '/assets/images/energies/hybrid-unavailable.svg',
        HYBRID_UNKNOWN: '/assets/images/energies/hybrid-unknown.svg',
    },
    iconMapCluster: '/assets/images/energies/cluster.svg',

    dashboard: dashboardEnvironment,

    //OBOU
    //ces variables sont nécessaire à la feature regions
    //elles sont définies en dur sur hydrowatt
    //une évolution devra être réalisée pour résorber cette dette technique
    regions: [],

    stationTags: [],

    stationsTargets: [],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
