/* tslint:disable */

/**
 * The list of auth support fields that change is historized:
 *   * VALUE
 *   * TYPE
 *   * PROVIDER
 *   * SECRET_CODE
 *   * INTERNAL_VALUE
 *   * STATE
 *   * VALIDITY_START
 *   * VALIDITY_END
 *   * COMMENT
 *   * SERVICE
 *   * REQUEST_KM
 *   * LANG_CODE
 *   * REASON
 *   * PARC_NUMBER
 *   * PLATE_NUMBER
 *   * CEILING
 *   * DRIVER_CODE
 *   * SUBSCRIPTION
 *   * MILEAGE_RESET
 */
type AuthSupportFieldChange =
  'VALUE' |
  'TYPE' |
  'PROVIDER' |
  'SECRET_CODE' |
  'INTERNAL_VALUE' |
  'STATE' |
  'VALIDITY_START' |
  'VALIDITY_END' |
  'COMMENT' |
  'SERVICE' |
  'REQUEST_KM' |
  'LANG_CODE' |
  'REASON' |
  'PARC_NUMBER' |
  'PLATE_NUMBER' |
  'CEILING' |
  'DRIVER_CODE' |
  'SUBSCRIPTION' |
  'MILEAGE_RESET';
module AuthSupportFieldChange {
  export const VALUE: AuthSupportFieldChange = 'VALUE';
  export const TYPE: AuthSupportFieldChange = 'TYPE';
  export const PROVIDER: AuthSupportFieldChange = 'PROVIDER';
  export const SECRET_CODE: AuthSupportFieldChange = 'SECRET_CODE';
  export const INTERNAL_VALUE: AuthSupportFieldChange = 'INTERNAL_VALUE';
  export const STATE: AuthSupportFieldChange = 'STATE';
  export const VALIDITY_START: AuthSupportFieldChange = 'VALIDITY_START';
  export const VALIDITY_END: AuthSupportFieldChange = 'VALIDITY_END';
  export const COMMENT: AuthSupportFieldChange = 'COMMENT';
  export const SERVICE: AuthSupportFieldChange = 'SERVICE';
  export const REQUEST_KM: AuthSupportFieldChange = 'REQUEST_KM';
  export const LANG_CODE: AuthSupportFieldChange = 'LANG_CODE';
  export const REASON: AuthSupportFieldChange = 'REASON';
  export const PARC_NUMBER: AuthSupportFieldChange = 'PARC_NUMBER';
  export const PLATE_NUMBER: AuthSupportFieldChange = 'PLATE_NUMBER';
  export const CEILING: AuthSupportFieldChange = 'CEILING';
  export const DRIVER_CODE: AuthSupportFieldChange = 'DRIVER_CODE';
  export const SUBSCRIPTION: AuthSupportFieldChange = 'SUBSCRIPTION';
  export const MILEAGE_RESET: AuthSupportFieldChange = 'MILEAGE_RESET';
  export function values(): AuthSupportFieldChange[] {
    return [
      VALUE,
      TYPE,
      PROVIDER,
      SECRET_CODE,
      INTERNAL_VALUE,
      STATE,
      VALIDITY_START,
      VALIDITY_END,
      COMMENT,
      SERVICE,
      REQUEST_KM,
      LANG_CODE,
      REASON,
      PARC_NUMBER,
      PLATE_NUMBER,
      CEILING,
      DRIVER_CODE,
      SUBSCRIPTION,
      MILEAGE_RESET
    ];
  }
}

export { AuthSupportFieldChange }