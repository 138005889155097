import { TableFtpConnectivityListMapper } from "src/app/mapper/table/table-ftp-connectivity-list.mapper";
import { StationFilterType } from "src/app/shared/models/station/station-filter-type";

/**
 * The column ids for the {@link TableFtpConnectivityListMapper}
 */
export type TableFtpConnectivityListColumn =
  | typeof StationFilterType.NAME
  | typeof StationFilterType.CODE
  | typeof StationFilterType.NETWORK
  | "BADGEUR_NAME"
  | "DISCONNECT_DATE"
  | "DISCONNECT_DURATION";

export namespace TableFtpConnectivityListColumn {
  export const NAME = "NAME";
  export const CODE = "CODE";
  export const NETWORK = "NETWORK";
  export const BADGEUR_NAME = "BADGEUR_NAME";
  export const DISCONNECT_DATE = "DISCONNECT_DATE";
  export const DISCONNECT_DURATION = "DISCONNECT_DURATION";

  export function values(): readonly TableFtpConnectivityListColumn[] {
    return [
      NAME,
      CODE,
      NETWORK,
      BADGEUR_NAME,
      DISCONNECT_DATE,
      DISCONNECT_DURATION,
    ];
  }
}
