import { iconClass } from "../enums/icon-class.enum";
import { RouteId } from "../enums/env/routes-id-enum";
import { RouteEnv } from "../interfaces/env/RouteEnv";


export class MainRoute {
  id: RouteId;
  path: string;
  pathSlash: string;
  name: string;
  icon: iconClass;
  detailBackLink: RouteId;
  backLinks: RouteId[];
  params: any;
  enabled: boolean;
  route!: string;

  constructor(dataFromEnv: RouteEnv, routeId: RouteId) {
    if (dataFromEnv) {
      this.id = dataFromEnv.id || null;
      this.path = dataFromEnv.route || '';
      this.pathSlash = '/' + dataFromEnv.route || '';
      this.name = dataFromEnv.name || null;
      this.icon = dataFromEnv.icon || null;
      this.detailBackLink = dataFromEnv.detailBackLink || null;
      this.backLinks = dataFromEnv.backLinks || null;
      this.enabled = dataFromEnv.enabled || null;
      this.params = dataFromEnv.params || null;
      this.route = dataFromEnv.route;
    } else {
      console.error('Il manque la route dans l\'environement core: ' + routeId);
    }
  }
}
